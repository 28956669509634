import React, { useState } from "react";
import styled from "styled-components";

// Component Header
const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Toggle menu mobile
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <HeaderContainer>
      <div className="logo">Nike Shoes</div>

      {/* Biểu tượng hamburger cho mobile */}
      <HamburgerMenu onClick={toggleMenu}>
        <span />
        <span />
        <span />
      </HamburgerMenu>

      {/* Nav cho mobile và desktop */}
      <Nav isMenuOpen={isMenuOpen}>
        <a href="#about" onClick={toggleMenu}>Giới Thiệu Về Chúng Tôi</a>
        <a href="#products" onClick={toggleMenu}>Sản Phẩm</a>
        <a href="#features" onClick={toggleMenu}>Tính Năng Vượt Trội</a>
        <a href="#testimonials" onClick={toggleMenu}>Phản Hồi Từ Khách Hàng</a>
        <a href="#faq" onClick={toggleMenu}>FAQ</a>
      </Nav>

      {/* Button CTA */}
      <button className="cta">Mua Ngay</button>
    </HeaderContainer>
  );
};

// Styles cho header
const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #fff;
  position: relative;

  .cta {
    background-color: #000;
    color: #fff;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-size: 18px;
  }

  .logo {
    font-size: 24px;
    font-weight: bold;
    color:#000000;
  }
`;

// Styles cho biểu tượng hamburger
const HamburgerMenu = styled.div`
  display: none;
  flex-direction: column;
  cursor: pointer;

  span {
    height: 2px;
    background: black;
    margin: 4px 0;
    width: 25px;
  }

  @media (max-width: 768px) {
    display: flex;
  }
`;

// Styles cho navigation menu
const Nav = styled.nav`
  display: flex;
  a {
    margin-right: 20px;
    text-decoration: none;
    color: #000;
    font-size: 18px;
    transition: color 0.3s;

    &:hover {
      color: #f00;
    }
  }

  @media (max-width: 768px) {
    /* Ẩn menu khi không được mở trên mobile */
    display: ${({ isMenuOpen }) => (isMenuOpen ? "flex" : "none")};
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    padding: 20px 0;

    a {
      margin: 10px 0;
      text-align: center;
      font-size: 20px;
    }
  }
`;

export default React.memo(Header);
