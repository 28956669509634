import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Home from "./view/Home";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { useWindowScroll } from "react-use";

const App = () => {
    const [menuBg, setMenuBg] = useState(false);
    const { y: pageOffset } = useWindowScroll();

    useEffect(() => {
        if (pageOffset > 70) {
            setMenuBg(true);
        } else {
            setMenuBg(false);
        }
    }, [pageOffset]);

    // PureChat integration
    useEffect(() => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.dataset.cfasync = 'false';
        script.innerHTML = `
            window.purechatApi = { l: [], t: [], on: function () { this.l.push(arguments); } };
            (function () {
                var done = false;
                var script = document.createElement('script');
                script.async = true;
                script.type = 'text/javascript';
                script.src = 'https://app.purechat.com/VisitorWidget/WidgetScript';
                document.getElementsByTagName('HEAD').item(0).appendChild(script);
                script.onreadystatechange = script.onload = function (e) {
                    if (!done && (!this.readyState || this.readyState === 'loaded' || this.readyState === 'complete')) {
                        var w = new PCWidget({ c: '3aafe693-f405-4934-bd3d-22a090aa0845', f: true });
                        done = true;
                    }
                };
            })();
        `;
        document.body.appendChild(script);

        // Cleanup when component unmounts
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <StyledScreen>
            <Router>
                <Header />
                <Switch>
                    <Route path="/" exact>
                        <Home />
                    </Route>
                </Switch>
            </Router>
            <WrapperFooter>
                <Footer />
            </WrapperFooter>
        </StyledScreen>
    );
};

const StyledScreen = styled.div`
    position: relative;
`;

const WrapperFooter = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export default App;
