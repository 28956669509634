import React from "react";
import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";
import { Container, Row, Col } from "reactstrap";


const Groupcs  = styled.div`
  display:flex;
  align-items: center;
  position: relative;
  flex-wrap:wrap;

  @media (max-width: 770px) {

  }

  .boxbrand{
    max-width:400px;
    width:100%;

    img{
      width:100%;
    }
  }
  .boxslide{
    width:calc(100% - 400px);

    @media (max-width: 770px) {
      width:100%
    }
  }


`;



const BoxUse  = styled.section`
  padding-top:4rem;
  position: relative;
  min-height:40vh;

  @media (max-width: 770px) {

  }


`;

export default function Testimonials() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    rows: 1,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <BoxUse id="testimonials" className="testimonials">
    <Container>
    <h2 className="titlepage"><span>Khách Hàng</span> Nói Gì Về Chúng Tôi?</h2>
    <Groupcs>
          <div className="boxbrand">
                <img src="https://giaygiare.store/wp-content/uploads/2024/04/THIEN-STORE-Logo-JPG.png.webp" alt="" />
          </div>
          <div className="boxslide">
          <Slider {...settings}>
        <div className="line-guest">
          <div className="img-guest">
            <img src="https://ik.imagekit.io/s7qfxbeum/IMG_5883.JPG?updatedAt=1725992974652" alt="Khách Hàng Nói Gì Về Nike?"/>
          </div>
        </div>
        <div className="line-guest">
          <div className="img-guest">
            <img src="https://ik.imagekit.io/s7qfxbeum/IMG_5876.JPG?updatedAt=1725992974534" alt="Khách Hàng Nói Gì Về Nike?"/>
          </div>
        </div>
        <div className="line-guest">
          <div className="img-guest">
            <img src="https://ik.imagekit.io/s7qfxbeum/IMG_5884.JPG?updatedAt=1725992974526" alt="Khách Hàng Nói Gì Về Nike?"/>
          </div>
        </div>
        <div className="line-guest">
          <div className="img-guest">
            <img src="https://ik.imagekit.io/s7qfxbeum/IMG_5879.JPG?updatedAt=1725992974515" alt="Khách Hàng Nói Gì Về Nike?"/>
          </div>
        </div>
        <div className="line-guest">
          <div className="img-guest">
            <img src="https://ik.imagekit.io/s7qfxbeum/IMG_5877.JPG?updatedAt=1725992974525" alt="Khách Hàng Nói Gì Về Nike?"/>
          </div>
        </div>
        <div className="line-guest">
          <div className="img-guest">
            <img src="https://ik.imagekit.io/s7qfxbeum/IMG_5881.JPG?updatedAt=1725992974472" alt="Khách Hàng Nói Gì Về Nike?"/>
          </div>
        </div>
        <div className="line-guest">
          <div className="img-guest">
            <img src="https://ik.imagekit.io/s7qfxbeum/IMG_5878.JPG?updatedAt=1725992974452" alt="Khách Hàng Nói Gì Về Nike?"/>
          </div>
        </div>
        <div className="line-guest">
          <div className="img-guest">
            <img src="https://ik.imagekit.io/s7qfxbeum/IMG_5880.JPG?updatedAt=1725992974443" alt="Khách Hàng Nói Gì Về Nike?"/>
          </div>
        </div>
      </Slider>
          </div>
    </Groupcs>
    </Container>
     </BoxUse>
  );
}