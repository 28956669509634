import { useEffect, useState } from 'react';
import axios from 'axios';

const ProductShowcase = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const categoryId = 68; // ID danh mục cố định

  useEffect(() => {
    const consumerKey = 'ck_220a94bc6960c5a396901a5e31bdb3153f8d72c0';
    const consumerSecret = 'cs_15912a7cbb803cbafe5ee73e3a336b2684369458';

    axios
      .get(`https://giaygiare.store/wp-json/wc/v3/products`, {
        params: {
          category: categoryId,
          per_page: 12,
          orderby: 'date',
          order: 'desc',
          status: 'publish',
        },
        auth: {
          username: consumerKey,
          password: consumerSecret,
        },
      })
      .then((response) => {
        setProducts(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching products:', error);
        setError('Error fetching products');
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <section id="products" className="product-showcase">
      <div>
        <h2 className="titlepage">Sản Phẩm <span>Nổi Bật</span> Của Nike</h2>
        <ul className="listshop">
          {products.map((product) => {
            // Kiểm tra giá trị slug
            console.log(product.slug);
            return (
              <li key={product.id}>
                <a target="_blank" href={`https://giaygiare.store/san-pham/${product.slug}`}>
                  <img src={product.images[0]?.src || '/default-image.jpg'} alt={product.name} />
                  <h2>{product.name}</h2>
                  <div className="scprice"> <button>Xem Thêm</button> <span>{product.price} {product.currency} vnd</span></div>
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </section>
  );
};

export default ProductShowcase;
