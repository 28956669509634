import React, { Component } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "reactstrap";

class HeroSection extends Component {
    render() {
        return (
          <section className="hero">
          {/* <h1>Chinh Phục Mọi Địa Hình - Giày Nike Chính Hãng</h1>
          <p>Giày Nike - Sự Lựa Chọn Của Vận Động Viên Chuyên Nghiệp</p> */}
          <BoxHero>
          <button className="cta">Khám Phá Bộ Sưu Tập</button>
          </BoxHero>

        </section>
        );
    }
}




const Box_home = styled.section`
    position: relative;
    overflow: hidden;
    z-index:1;
    padding: 55px 0;

    @media (max-width: 770px) {
      padding:25px 0;
    }

    .gkl41{
      text-align:center;
      img{
        max-width:85%;

        @media (max-width: 770px) {
          max-width:90%;
        }

      }

      @media (max-width: 770px) {
      padding:10px;
      }
    }
`;


const BoxHero = styled.div`
   width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 85vh;
  position: relative;

  @media (max-width: 770px) {

  }

  .cta{
    margin-top:0;
    position: absolute;
    bottom: 10%;
  }


`;




export default HeroSection;
