import React, { Component } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "reactstrap";
// import ProgressBar from 'react-bootstrap/ProgressBar'

class FAQ extends Component {
    render() {
        return (
          <section id="faq" className="faq">
            <h2 className="titlepage">Câu Hỏi <span>Thường Gặp</span></h2>
            <H3tiel>
              <h3>Giày có bảo hành không?</h3>
              <p>Có, tất cả các sản phẩm đều có bảo hành chính hãng 1 năm.</p>
            </H3tiel>
            <H3tiel>
              <h3>Làm thế nào để đổi trả sản phẩm?</h3>
              <p>Bạn có thể đổi trả trong vòng 30 ngày nếu sản phẩm không vừa ý.</p>
            </H3tiel>
        </section>
        );
    }
}




const Box_home = styled.section`
    position: relative;
    overflow: hidden;
    z-index:1;
    padding: 0 0 35px 0;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    .ifk8{
      text-align:center;
      img{
        max-width:80%;
      }
    }

`;





const H3tiel = styled.div`
  color: #ffffff;
  background: #2196F3;
  text-align: left;
  max-width: 900px;
  margin: auto;
  width: 100%;
  padding: 10px 20px;
  margin-bottom: 25px;
  border-radius: 15px;

  h3{
    font-size:24px;
  }
`;


export default FAQ;
