import { useState, useEffect } from 'react';
import styled from "styled-components";


const ModalOver = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;

  @media (max-width: 770px) {

  }

`;


const ModalContent = styled.div`
  background-color: rgb(61, 158, 231);
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  max-width: 400px;
  width: 100%;

  @media (max-width: 770px) {
    width: 95%;
  }

  input{
    width:100%;
    margin-bottom:15px;
    background:#ffffff;
    color:#000000;
    border:none;
    height:40px;
    padding:5px 10px;
    outline: none;
  }

  .btnsb{
    max-width: 100px;
    width: 100%;
    border: none;
    border-radius: 20px;
    height: 35px;
    margin-bottom: 15px;

    &:hover{
      opacity:.5;
    }
  }

`;
const TitleMD = styled.h3`
  font-size:20px;
  margin-bottom:15px;
  color:#ffffff;

  @media (max-width: 770px) {

  }

`;



const BtnModal = styled.button`
 padding: 10px 20px;
  background-color: #00796b;
  color: rgb(17, 1, 1);
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  @media (max-width: 770px) {

  }

`;


export default function Modal() {
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    size: '',
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsOpen(true);
    }, 7000); // Hiển thị modal sau 5 giây

    return () => clearTimeout(timer);
  }, []);

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await fetch('https://script.google.com/macros/s/AKfycbwQNpvQbHbrprHYqqC5Q2-i4d8jpfmQ5Xp0TGH-DA8vVzP3q1Xw0g9Viz-KlhO3IIJd/exec', {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams(formData),
    });

    if (response.ok) {
      alert('Xác nhận tham gia nhận khuyến mãi thành công. Shop sẽ liên hệ đến bạn!');
      closeModal();
    } else {
      alert('Có lỗi khi gửi thông tin. Vui lòng thử lại sau.');
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  if (!isOpen) return null;

  return (
    <ModalOver>
      <ModalContent>
        <TitleMD>Đăng ký thông tin - Nhận ngày giảm giá mua hàng 30%!</TitleMD>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            name="name"
            placeholder="Tên của bạn"
            value={formData.name}
            onChange={handleChange}
            required
          />
          <input
            type="text"
            name="phone"
            placeholder="Số điện thoại"
            value={formData.phone}
            onChange={handleChange}
            required
          />
          <input
            type="text"
            name="size"
            placeholder="Size giày"
            value={formData.size}
            onChange={handleChange}
            required
          />
          <button className="btnsb" type="submit">Gửi</button>
        </form>
        <BtnModal onClick={closeModal}>Đóng</BtnModal>
      </ModalContent>
    </ModalOver>
  );
}
