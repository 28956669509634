import React, { Component } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "reactstrap";

class Features extends Component {
    render() {
        return (
          <BoxFeature id="features" className="features">
          <h2 className="titlepage">Tại Sao <span>Chọn Nike?</span></h2>
          <ListFt>
            <div> <img src="https://ik.imagekit.io/s7qfxbeum/Ellipse%204.svg?updatedAt=1726303834600" alt="" /> <span>Công nghệ đệm siêu nhẹ</span></div>
            <div> <img src="https://ik.imagekit.io/s7qfxbeum/Ellipse%203.svg?updatedAt=1726303834554" alt="" /> <span>Đế giày chống trượt</span></div>
            <div> <img src="https://ik.imagekit.io/s7qfxbeum/Ellipse%205.svg?updatedAt=1726303834460" alt="" /> <span>Thiết kế thông thoáng</span></div>
            <div> <img src="https://ik.imagekit.io/s7qfxbeum/Ellipse%202.svg?updatedAt=1726303834406" alt="" /> <span>Độ bền cao</span></div>
          </ListFt>
        </BoxFeature>
        );
    }
}




const BoxFeature = styled.section`
    position: relative;
    overflow: hidden;
    padding:70px 0;
    background: linear-gradient(90deg, #fcfcfc 0, #a6d7ff 47%, #ffffff 100%);
    background-attachment: fixed;


    @media (max-width: 770px) {
      padding:20px 0;
    }

`;



const ListFt = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  flex-wrap: wrap;

  div{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;

    span{
      width:100%;
      font-size: 22px;
      font-weight: 600;
      color: #000000;
    }

  }


`;



const Nav = styled.div`
  float: right;
  position: relative;
  margin-top: 25px;

  ul{
    list-style: none;
    display: flex;
    align-items: center;
    gap: 10px;

    li{
      max-width:100px;
      width:100%;

      a{
        :hover{
          opacity:.5;
        }
      }
    }
  }

  @media (max-width: 770px) {

  }
`;



export default Features;
