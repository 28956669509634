export default function Footer() {
    return (
      <footer className="footer">
        <p>© 2024 Hoka Shoes. All rights reserved.</p>
        <div className="social-links">
          <a href="https://facebook.com">Facebook</a>
          <a href="https://instagram.com">Instagram</a>
          <a href="https://twitter.com">Twitter</a>
        </div>
      </footer>
    );
  }
