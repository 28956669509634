import React, { useEffect, useState } from "react";
import styled from "styled-components";


import ScrollTop from "../../components/ScrollTop";
import { useWindowScroll } from "react-use";
import Aboutus from "./components/Aboutus";
import HeroSection from "./components/HeroSection";
import ProductShowcase from "./components/ProductShowcase";
import FAQ from "./components/FAQ";
import Features from "./components/Features";
import Testimonials from "./components/Testimonials";
import Modal from "./components/Modal";

const Home = () => {
    const { y: pageOffset } = useWindowScroll();
    const [visible, setVisibility] = useState(false);
    useEffect(() => {
        if (pageOffset > 400) {
            setVisibility(true);
        } else {
            setVisibility(false);
        }
    }, [pageOffset]);
    return (
        <Box_home>
            <HeroSection/>
            <Aboutus/>
            <ProductShowcase/>
            <Features/>
            <Testimonials/>
            <FAQ/>
            <Modal/>
        </Box_home>
    );
};
const Box_home = styled.section`
    position: relative;
    overflow: hidden;
    min-height:100vh;
    padding: 0 0 35px 0;
    background: #ffffff;
    /* background: linear-gradient(90deg, #000 0, #0e4c66 47%, #000 100%); */
    @media (max-width: 770px) {
      padding:15px;
    }
    ::after{
        /* position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        background-position: center;
        z-index: 0;
        top: 0;
        left: 0;
        background-size: contain;
        pointer-events: none;
        background-attachment: fixed; */
    };
`;
const Title_home = styled.h2`
    color: ${(props) => props.color};
    font-size: 40px;
    margin-bottom: 24px;
    font-weight: bold;
    line-height: 1.1;
`;

export default React.memo(Home);
