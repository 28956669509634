import React, { useState, useRef, useEffect } from 'react';
import styled from "styled-components";

const Aboutus = () => {


  return (
    <section id="about" className="about-us">
      <h2 className="titlepage">Về Chúng Tôi</h2>
      <TitleMusic>Tại ThienStore, chúng tôi tự hào mang đến cho bạn bộ sưu tập giày Nike mới nhất và tốt nhất. Được thiết kế với công nghệ tiên tiến và phong cách không thể chối từ, mỗi đôi giày Nike đều mang đến sự thoải mái và hiệu suất tối ưu cho mọi bước chân.</TitleMusic>
  </section>
  );
};




const LoopMusic = styled.div`
  display:flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  margin-top: 10px;

  input{
    transform: scale(1.4);
  }

  @media (max-width: 770px) {

  }

`;

const TitleMusic  = styled.p`
  color:#000000;
  max-width:800px;
  width:100%;
  margin:auto;

  @media (max-width: 770px) {

  }

`;


const Libary  = styled.h3`
  color: #ffffff;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  justify-content: flex-start;

  @media (max-width: 770px) {

  }

`;




const ThumbMusic = styled.div`
  position: relative;
  min-height: 320px;
  max-height: 325px;
  overflow: hidden;

  img{
    object-fit: cover;
    height: 100%;
  }

  @media (max-width: 770px) {

  }

`;

const ListMusic = styled.ul`
  list-style: none;
  background: rgb(0 7 10 / 24%);
  height: 200px;
  overflow-y: scroll;
  padding: 10px;
  border-radius: 10px;
  color: #ffffff;
  font-size: 18px;

  li{
    background: #a2cbeb;
    border-radius: 4px;
    margin-bottom: 5px;
    padding: 5px 0;
  }

  @media (max-width: 770px) {

  }

`;


const GroupPlay = styled.div`
  padding: 10px;
  background: rgb(0 7 10 / 24%);
  border-radius: 60px;
  max-width: 80%;
  margin: auto;
  margin-top: 1rem;

  button{
    background: transparent;

    &:hover{
      opacity:.5
    }
  }

  @media (max-width: 770px) {

  }

`;

export default Aboutus;


